// @ts-strict-ignore
import React from 'react';
import { ValidatingFormComponent } from '@/formbuilder/formBuilder.constants';
import { Field, useForm } from 'react-final-form';
import _ from 'lodash';
import { CopyableInputField } from '@/core/CopyableInputField.molecule';
import { FormFieldWrapper } from '@/formbuilder/FormFieldWrapper';
import { getFormFieldProps } from '@/formbuilder/formbuilder.utilities';

export interface CopyableInputIF extends ValidatingFormComponent<string> {
  component: 'CopyableInputFormComponent';
  /** an identifier to be used in tests */
  testId: string;
  /** true if the field should be read only, false otherwise */
  readOnly: boolean;
  /** string to display in the tooltip when hovering the input field */
  fieldTooltip: string;
  /** string to display in the tooltip when hovering the copy button */
  copyButtonTooltip: string;
  /** value to show in the input field */
  value?: string;
  /** notification message sent to user when clicking the copy button */
  copyNotifyMessage?: string;
  /** the extra buttons to render between the input field and the copy button */
  extraButtons?: JSX.Element[];
  copyDisabled?: boolean;
}

export const CopyableInputFormComponent: React.FunctionComponent<CopyableInputIF> = (props) => {
  const {
    name,
    testId,
    validation,
    readOnly,
    fieldTooltip,
    copyButtonTooltip,
    copyNotifyMessage,
    extraButtons,
    label,
    copyDisabled,
  } = props;

  const formState = useForm().getState();
  const showError =
    _.has(formState.errors, name) &&
    formState.hasValidationErrors &&
    (formState.modified[name] || formState.submitFailed);
  const defaultValidation = (v) => _.isEmpty(_.trim(v));
  const appliedValidation = _.isFunction(validation) ? validation : defaultValidation;

  return (
    <FormFieldWrapper testId={testId} label={label} showError={showError}>
      <Field name={name} validate={appliedValidation}>
        {({ input, meta }) => {
          const { onChange, value } = getFormFieldProps(formState, input, meta, props);
          return (
            <CopyableInputField
              testId={`${testId}Field`}
              readOnly={readOnly}
              fieldTooltip={fieldTooltip}
              value={value}
              notifyMessage={copyNotifyMessage}
              buttonTooltip={copyButtonTooltip}
              extraButtons={extraButtons}
              onChange={onChange}
              showError={showError}
              copyDisabled={copyDisabled}
            />
          );
        }}
      </Field>
    </FormFieldWrapper>
  );
};
