import { ColumnTypeEnum } from '@/sdk/model/ColumnDefinitionInputV1';
import type { ColDef } from '@ag-grid-community/core';
import { ColumnDefinitionInputV1, ColumnDefinitionOutputV1, RowRulesResultV1, RulesResultV1 } from '@/sdk';
import { FormulaErrorInterface } from '@/formula/formula.types';

export interface MaterializedTableOutput {
  rows: (string | number | boolean | undefined)[][];
  headers: MaterializedTableHeader[];
  hasMore: boolean;
}

interface TableCell {
  override: boolean;
  evaluationDetails?: RulesResultV1[];
}

export interface ItemTableCell extends TableCell {
  uuid: string;
  fetchedProperties?: { [key: string]: string | undefined };
}

export interface NumericTableCell extends TableCell {
  value: number;
  uom?: string;
}

export interface TextTableCell extends TableCell {
  value?: string;
}

export interface BooleanTableCell extends TableCell {
  value: boolean;
}

export type ProcessedTableCell = ItemTableCell | NumericTableCell | TextTableCell | BooleanTableCell;

export interface ProcessedMaterializedTable {
  rows: ProcessedTableCell[][];
  headers: MaterializedTableHeader[];
  hasMore: boolean;
}

export interface MaterializedTableHeader {
  name: string;
  type: ColumnTypeEnum;
}

export interface ScalingTableColumnDefinition extends ColumnDefinitionOutputV1 {
  /** Item Columns use this field for which Item Property that column is currently displaying */
  propertyToDisplay?: string;
  /** Item columns use this field to keep track of all Properties that have been fetched, in conjunction with
   *  propertyToDisplay, so we can cache the values for each property when we set the results of a Materialized
   *  Table in the store */
  additionalProperties?: string[];
  /** For most columns this will be the columnName, but some columns need a UI override for their name if they are
   * not editable by the user
   */
  displayName: string;

  evaluationDetails?: RowRulesResultV1[];
}

export interface DecoratedScalingTableColumnDefinition extends ScalingTableColumnDefinition {
  dropdownDescription?: string;
  isAllowedForFirstInput?: boolean;
  isFormulaColumn?: boolean;
  isAssetColumn?: boolean;
  columnIsTextWithOnlyOneValue?: boolean;
  isMadeWithCreatorRule?: boolean;
  isHiddenFromDropdown?: boolean;
}

export interface PotentialColumnDefinition extends ColumnDefinitionInputV1 {
  // The index of the column in the list of all columns, this is 1-based
  columnIndex: number;
  sourceColumnDisplayName: string;
  isOptionForDropdown: boolean;
  sourceColumnDisplayNameIfSourceIsNotYetCreated?: string;
  isAssetColumn?: boolean;
  isFormulaColumn?: boolean;
  dropdownDescription?: string;
  isAllowedForFirstInput?: boolean;
}

export interface MaterializedTableItemColumnPropertyPair {
  uuidColumn: string;
  propertyName: string;
}

export interface MaterializedTablePropertyColumnInput {
  uuidColumn: string;
  propertyNames: string[];
}

export interface FormulaCompileResult {
  success: boolean;
  errors: FormulaErrorInterface[];
}

export const DEFAULT_TABLE_DEFINITION_NAME = 'New Table';

export const PROPERTY_COLUMN_MATCH_SEGMENT = '.properties.';
export const UOM_COLUMN_MATCH_SUFFIX = '_uom';

export interface AgGridScalingColumnDefinition extends ColDef {
  headerValueGetter: () => string;
  propertyToDisplay?: string;
}

export interface TableDefinitionAccessSettings {
  /** Workbook ID or undefined to make table global */
  scopedTo?: string;
}

export interface EvaluationResults {
  rows: ProcessedTableCell[][];
  headers: MaterializedTableHeader[];
  hasMore: boolean;
}
