import React from 'react';
import _ from 'lodash';
import { Field, useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import {
  getFormFieldProps,
  getFormFieldWrapperProps,
  getValidationFunction,
} from '@/formbuilder/formbuilder.utilities';
import { ValidatingFormComponent } from '@/formbuilder/formBuilder.constants';
import { FormFieldWrapper } from '@/formbuilder/FormFieldWrapper';
import { TextField } from '@seeqdev/qomponents';
import DatePicker from 'react-datepicker';
import { formatDate } from '@/vantage/dateTime.utilities';

export interface DatePickerFormComponentProps extends ValidatingFormComponent<string | Date> {
  component: 'DatePickerFormComponent';
  id?: string;
  placeholder?: string;
  customErrorText?: string;
  labelIcon?: string;
  labelIconAction?: () => void;
  labelIconTooltip?: string;
  labelClassNames?: string;
  getDisplayDate?: (date: Date | undefined) => string;
}
const DEFAULT_DATE_FORMAT = 'MMM-DD-YYYY';
export const DatePickerFormComponent: React.FunctionComponent<DatePickerFormComponentProps> = (props) => {
  const {
    name,
    extendValidation,
    validation,
    placeholder,
    onChange,
    testId = 'formControl',
    required,
    customErrorText,
    labelIcon,
    labelIconAction,
    labelIconTooltip,
    labelClassNames,
    getDisplayDate,
    value,
  } = props;

  const { t } = useTranslation();

  const defaultValidation = (value: string) => required && _.isEmpty(_.trim(value));

  const defaultDisplayDate = (date: Date | undefined) => {
    return date ? formatDate(date!, DEFAULT_DATE_FORMAT) : '';
  };

  const appliedValidation = getValidationFunction(defaultValidation, extendValidation, validation);
  const formState = useForm().getState();
  const showError =
    _.has(formState.errors, name) &&
    formState.hasValidationErrors &&
    (formState.submitFailed || (formState.modified && formState.modified[name]));

  return (
    <FormFieldWrapper
      {...getFormFieldWrapperProps(props, ['id'])}
      testId={`${testId}-wrap`}
      labelIcon={labelIcon}
      labelIconAction={labelIconAction}
      labelIconTooltip={labelIconTooltip}
      labelClassNames={labelClassNames}
      showError={showError}
      customErrorText={customErrorText}>
      <Field name={name} validate={appliedValidation}>
        {({ input, meta }) => {
          const properProps = _.assign({}, getFormFieldProps(formState, input, meta, props), {
            placeholder: placeholder && t(placeholder),
            value: input.value,
            onChange: (date: Date | null) => {
              input.onChange(date);
              date && onChange?.(date);
            },
            onFocus: (e: any) => e.target.select(),
          });

          const displayValue = input.value ? input.value : value;

          return (
            <DatePicker
              portalId="datepicker-portal"
              {...properProps}
              selected={displayValue}
              customInput={
                <div>
                  <TextField
                    testId={testId}
                    extraClassNames={'calendarInput ml5'}
                    value={getDisplayDate ? getDisplayDate(displayValue) : defaultDisplayDate(displayValue)}
                    showError={showError}
                  />
                </div>
              }
              required={properProps.showError && properProps.required}
            />
          );
        }}
      </Field>
    </FormFieldWrapper>
  );
};
