import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import _ from 'lodash';
import { Button, Icon, Select, TextField } from '@seeqdev/qomponents';
import { useFlux } from '@/core/hooks/useFlux.hook';
import { IconSelect } from '@/core/IconSelect.molecule';
import { KEY_CODES } from '@/main/app.constants';
import { getTypeTranslation } from '@/utilities/utilities';
import { SORT_BY_OPTIONS } from '@/search/search.constants';
import { doTrack } from '@/track/track.service';
import {
  clear,
  getStoreForPane,
  searchItems,
  setAdvancedMode,
  setDescriptionFilter,
  setIsExactName,
  setIsUsingDatasourcePrefsSearchFilters,
  setNameFilter,
  setSelectedDatasources,
  setSelectedTypes,
  setSortBy,
} from '@/search/search.actions';
import { Checkbox } from '@/core/Checkbox.atom';
import { Datasource, SearchFiltersProps } from '@/search/search.types';
import { AnyProperty } from '@/utilities.types';
import { ActiveFilters } from '@/search/ActiveFilters';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { sqWorkbenchStore } from '@/core/core.stores';
import { setUserPreferencesDisplay, setUserPreferencesTab } from '@/workbench/workbench.actions';
import { PREFERENCE_TABS } from '@/workbench/workbench.store';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { hasInValidSearchTypes } from '@/search/search.utilities';
import { DatasourcePreferencesToggle } from '@/search/DatasourcePreferencesToggle.molecule';
import { SelectOptionIF } from '@/usage/Usage.page';
import { focusAndSelectElement } from '@/utilities/dom.utilities';

const SEARCH_TYPES_TO_COMBINE: Record<string, string> = {
  [SeeqNames.Types.CalculatedCondition]: SeeqNames.Types.Condition,
  [SeeqNames.Types.StoredCondition]: SeeqNames.Types.Condition,
  [SeeqNames.Types.CalculatedSignal]: SeeqNames.Types.Signal,
  [SeeqNames.Types.StoredSignal]: SeeqNames.Types.Signal,
  [SeeqNames.Types.CalculatedScalar]: SeeqNames.Types.Scalar,
  [SeeqNames.Types.LiteralScalar]: SeeqNames.Types.Scalar,
};

const MIN_LENGTH_FOR_SHOWING_RECENT_DATASOURCES = 5;

/** This will prevent duplicate options when searching by item type */
const combineSearchTypes = (searchTypes: string[]) =>
  Array.from(
    new Set(
      searchTypes.map((type) => {
        const combinedType = SEARCH_TYPES_TO_COMBINE[type];

        return combinedType ? combinedType : type;
      }),
    ),
  );

/**
 * Make an array of objects the react-select can use.
 *
 * Construct the options list by calling omitOrIncludeUserDatasources, and also do not include anything
 * that is currently selected (check with 'datasourceFilter' from the flux store to know what is selected) so that
 * it does not appear in both the 'selected' area and the dropdown list.
 */
const buildSelectOptions = (
  datasources: Datasource[],
  datasourceFilter: Datasource[],
  isUsingDatasourcePrefsSearchFilters: boolean,
) => {
  const processDatasources = (dataSources: Datasource[]) => {
    return _.chain(dataSources)
      .map((datasource) => ({
        label: datasource.name,
        value: datasource,
      }))
      .sortBy((datasource) => datasource.label?.toLowerCase())
      .value();
  };

  if (isUsingDatasourcePrefsSearchFilters) {
    const filteredDatasources = sqWorkbenchStore.showOnlyTheseDatasources(datasources);
    const unselectedOnly = filteredDatasources.filter(
      (datasource) => !datasourceFilter.some((selected) => selected.id === datasource.id),
    );
    return processDatasources(unselectedOnly);
  } else {
    return processDatasources(datasources);
  }
};

export const SearchFilters: React.FunctionComponent<SearchFiltersProps> = (props) => {
  const { pane, scopeIds, excludeGloballyScoped = false } = props;
  const searchTypes = combineSearchTypes(props.searchTypes);
  const { t } = useTranslation();

  const nameFilterRef = useRef<HTMLInputElement>(null);
  const store = getStoreForPane(pane);

  const focusNameInput = () => nameFilterRef.current?.focus();
  useEffect(focusNameInput, []);

  const {
    nameFilter,
    descriptionFilter,
    searching,
    currentAsset,
    typeFilter,
    datasourceFilter,
    sortBy,
    isAdvancedMode,
    datasources,
    recentDatasourceFilters,
    isExactName,
    isUsingDatasourcePrefsSearchFilters,
  } = useFlux(store);

  const [searchValue, setSearchValue] = useState(nameFilter);
  const [descriptionSearch, setDescriptionSearch] = useState(descriptionFilter);

  useEffect(() => {
    setSearchValue(nameFilter);
    setDescriptionSearch(descriptionFilter);
  }, [nameFilter, descriptionFilter]);

  // ensure we re-render and display correct datasources
  useFluxPath(sqWorkbenchStore, () => sqWorkbenchStore.userDatasources);

  const search = async () => {
    setNameFilter(pane, searchValue);
    setDescriptionFilter(pane, descriptionSearch);

    await searchItems(pane, searchTypes, scopeIds, excludeGloballyScoped);

    const searchSummary: AnyProperty<string | number | boolean> = {
      advancedMode: isAdvancedMode,
      nameSpecified: !!nameFilter,
      descriptionSpecified: !!descriptionFilter,
      assetSpecified: !!currentAsset,
      datasourceFilterCount: _.size(datasourceFilter),
      sortBy,
    };
    searchTypes.forEach((searchType) => {
      searchSummary[`typeFilter-${searchType}`] = typeFilter.includes(searchType);
    });

    doTrack('Search', 'Search Complete', JSON.stringify(searchSummary));
  };

  /**
   * Using "searchTypes", make an array of objects the react-select can use.
   */
  const searchTypesOptions = searchTypes.map((type) => ({
    label: t(getTypeTranslation(type)),
    value: type,
  }));

  const datasourcesOptions = buildSelectOptions(datasources, datasourceFilter, isUsingDatasourcePrefsSearchFilters);
  const recentDatasourcesOptions = buildSelectOptions(
    recentDatasourceFilters,
    datasourceFilter,
    isUsingDatasourcePrefsSearchFilters,
  );

  /**
   * If there are more than a certain number of datasources, show a "Recent" section in the Datasources dropdown.
   * Ensure "recent" only contains datasources that actually still exist (ie: in case of deletion).
   * Filter out anything that is currently selected.
   * */
  const getDatasourceOptions = () => {
    if (datasourcesOptions.length >= MIN_LENGTH_FOR_SHOWING_RECENT_DATASOURCES) {
      return [
        {
          label: t('SEARCH_DATA.RECENT_DATASOURCE_FILTER_OPTIONS'),
          options: recentDatasourcesOptions.filter((recentOption) =>
            datasourcesOptions.some((datasourceOption) => datasourceOption.value.id === recentOption.value.id),
          ),
        },
        {
          label: isUsingDatasourcePrefsSearchFilters
            ? t('SEARCH_DATA.ALL_DATASOURCE_PREFERRED_ONLY')
            : t('SEARCH_DATA.ALL_DATASOURCE_FILTER_OPTIONS'),
          options: datasourcesOptions.filter(
            (datasourceOption) =>
              !recentDatasourcesOptions.some((recentOption) => recentOption.value.id === datasourceOption.value.id),
          ),
        },
      ];
    } else {
      return [
        {
          label: isUsingDatasourcePrefsSearchFilters
            ? t('SEARCH_DATA.ALL_DATASOURCE_PREFERRED_ONLY')
            : t('SEARCH_DATA.ALL_DATASOURCE_FILTER_OPTIONS'),
          options: datasourcesOptions,
        },
      ];
    }
  };

  const activeFilters = [
    { label: 'Description', filter: descriptionFilter, onClear: () => setDescriptionFilter(pane, '') },
    { label: 'Type', filter: typeFilter, onClear: () => setSelectedTypes(pane, []) },
    { label: 'Datasource', filter: datasourceFilter, onClear: () => setSelectedDatasources(pane, []) },
    { label: 'Sort', filter: sortBy, onClear: () => setSortBy(pane, '') },
  ];

  const hasTypesError = hasInValidSearchTypes(typeFilter);

  return (
    <div
      data-testid="searchFilters"
      className={classNames('card card-default', {
        borderGray: pane === 'modal',
        mt5: pane !== 'modal',
      })}>
      <div className="card-body" data-testid="searchPanel">
        <TextField
          extraClassNames="mb3 width-maximum"
          id="searchInput"
          name="nameFilter"
          testId="nameFilter"
          placeholder={t('SEARCH_DATA.NAME_CONTAINS')}
          value={searchValue}
          onFocus={focusAndSelectElement}
          onChange={(e) => setSearchValue(e.target.value)}
          onKeyUp={(e) => e.keyCode === KEY_CODES.ENTER && search()}
          ref={nameFilterRef}
        />

        <Checkbox
          label="EXACT_MATCH_CHECKBOX"
          isChecked={isExactName}
          classes="mb10"
          id="EXACT_MATCH_CHECKBOX"
          onChange={() => setIsExactName(pane, !isExactName)}
        />

        {!isAdvancedMode && <ActiveFilters selectedFilters={activeFilters} actionAfterFilterCleared={search} />}

        {isAdvancedMode && (
          <div className="advancedFormWrapper">
            <TextField
              extraClassNames="mb10 width-maximum"
              id="searchInput"
              name="descriptionFilter"
              testId="descriptionFilter"
              placeholder={t('SEARCH_DATA.DESCRIPTION_CONTAINS')}
              value={descriptionSearch}
              onFocus={focusAndSelectElement}
              onKeyUp={(e) => e.keyCode === KEY_CODES.ENTER && search()}
              onChange={(e) => setDescriptionSearch(e.target.value)}
            />
            {/* Item type filter */}
            <div data-testid="typeFilter" className="mb10 multiSelectTwoLine">
              <Select
                isMulti={true}
                isClearable={true}
                closeMenuOnSelect={false}
                isSearchable={true}
                options={searchTypesOptions}
                menuPortalTarget={document.body}
                placeholder={t('SEARCH_DATA.ALL_TYPES')}
                value={typeFilter.map((type) => ({ label: t(getTypeTranslation(type)), value: type }))}
                onChange={(selectedTypes: SelectOptionIF<string>[]) => {
                  setSelectedTypes(pane, selectedTypes);
                }}
                showError={hasTypesError}
              />
              {hasTypesError && <span className="sq-text-danger">{t('SEARCH_DATA.TYPES_ERROR')}</span>}
            </div>

            {/* Datasource filter */}
            <DatasourcePreferencesToggle
              on={isUsingDatasourcePrefsSearchFilters}
              onChange={() => setIsUsingDatasourcePrefsSearchFilters(pane, !isUsingDatasourcePrefsSearchFilters)}
              formattedLabel={t('SEARCH_DATA.USE_DATASOURCE_PREFERENCES')}
              onClick={() => {
                setUserPreferencesTab(PREFERENCE_TABS.DATASOURCES);
                setUserPreferencesDisplay(true);
              }}
            />
            <div data-testid="datasourceFilter" className="mb10 multiSelectLarge">
              <Select
                isMulti={true}
                isClearable={true}
                closeMenuOnSelect={false}
                isSearchable={true}
                options={getDatasourceOptions()}
                menuPortalTarget={document.body}
                placeholder={
                  isUsingDatasourcePrefsSearchFilters
                    ? t('SEARCH_DATA.ALL_DATASOURCE_PREFERRED_ONLY')
                    : t('SEARCH_DATA.ALL_DATASOURCE_FILTER_OPTIONS')
                }
                value={datasourceFilter.map((datasource) => ({
                  label: datasource.name,
                  value: datasource,
                }))}
                onChange={(selectedDatasources: SelectOptionIF<Datasource>[]) => {
                  setSelectedDatasources(pane, selectedDatasources);
                }}
              />
            </div>

            <IconSelect
              name="sortByFilter"
              value={sortBy}
              wrapperClasses="mb10"
              selectOptions={_.map(SORT_BY_OPTIONS, (option, key) => ({
                text: `SEARCH_DATA.SORT_BY_OPTIONS.${key}`,
                value: option,
              }))}
              onChange={(sort) => setSortBy(pane, _.toString(sort.value))}
            />
          </div>
        )}

        <div className="flexColumnContainer flexSpaceBetween">
          <Button
            label={t('SEARCH_DATA.RESET')}
            onClick={() => {
              setSearchValue('');
              setDescriptionSearch('');
              clear(pane, searchTypes, false, scopeIds, excludeGloballyScoped);
              nameFilterRef.current?.focus();
            }}
            testId="resetButton"
            extraClassNames="height-fit-content"
          />
          <div className="flexColumnContainer flexWrapReverse flexJustifyEnd flexAlignCenter gap10">
            <a
              className="force-link-color cursorPointer"
              data-testid="moreFiltersButton"
              onClick={() => setAdvancedMode(pane, !isAdvancedMode)}>
              {t('SEARCH_DATA.FILTER_AND_SORT')}
              <Icon icon={isAdvancedMode ? 'fa-angle-up' : 'fa-angle-down'} extraClassNames="ml2" />
            </a>
            <Button
              testId="searchButton"
              id="spec-search-btn"
              label={t('SEARCH')}
              variant="theme"
              icon={searching ? 'fa-spinner fa-spin-pulse' : 'fa-search'}
              iconPrefix={searching ? 'fa-solid' : ''}
              disabled={searching || hasTypesError}
              iconStyle="white"
              type="submit"
              onClick={search}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
